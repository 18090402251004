<template>
  <div>
    <label class="float-right pb-2"> {{data.length}} Eventos</label>
    <vs-table :data="sortedData" @sort="handleSort" :sst="true" current-sort-type="asc">
      <template slot="thead">
        <vs-th width="20%" sort-key="data.emitted_at">Data/Hora</vs-th>
        <vs-th sort-key="data.text">Descrição</vs-th>
        <vs-th v-if="hasJustification(data)" sort-key="data.justification">Justificativa</vs-th>
        <vs-th sort-key="type">Evento</vs-th>
        <vs-th sort-key="type">Tipo</vs-th>
        <vs-th sort-key="supervisor_name">Supervisor</vs-th>
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="data[i]" v-for="(tr, i) in data">
          <vs-td>
            {{ formattedDate(_.get(tr, 'data.emitted_at', null)) }}
          </vs-td>
          <vs-td>
            <div class="_event-detail-text overflow-y-scroll text-sm">
              <div v-html-safe="_.get(tr, 'data.text', '<label class=\'text-danger\'>VAZIO</label>')" />
            </div>
          </vs-td>
          <vs-td v-if="hasJustification(data)">
            <div class="_event-detail-text overflow-y-scroll break-all">
              {{justification(tr)}}
            </div>
          </vs-td>
          <vs-td :class="getSeverityClass(tr.severity)">
            {{ $t(`questionnaire_events.event.${tr.type}`) }}
          </vs-td>
          <vs-td>
            {{ tr.type }}
          </vs-td>
          <vs-td>
            {{ _.get(tr, 'supervisor_name') }}
          </vs-td>
          <vs-td>
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer"
              svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
              @click="openDataViewer(tr)"
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-popup :title="getDataViewerPopupTitle()" :active.sync="showDataViewer">
      <div class="mb-4 flex flex-col" v-if="currentlyViewingEvent && 'type' in currentlyViewingEvent">
        <div>
          <label class="font-bold">Tipo de Evento: </label><span>{{ _.get(currentlyViewingEvent, 'type') }}</span>
        </div>
      </div>
      <data-viewer :data="currentlyViewingData"/>
    </vs-popup>
  </div>
</template>

<script>
import { arraySort, arraySortDate } from '@/util/Util'
import DataViewer from '@/components/DataViewer.vue'
export default {
  components: { DataViewer },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    sortedData() {
      const data = this._.cloneDeep(this.data)
      const key = this._.get(this.sort, 'key', 'data.emitted_at')
      const direction = this._.get(this.sort, 'direction', 'desc')
      const directionSign = direction === 'asc' ? 1 : -1

      let sortFunction = arraySort(key, directionSign, true)

      if (key === 'data.emitted_at') sortFunction = arraySortDate(key, directionSign)

      data.sort(sortFunction)

      return data
    }
  },

  data: () => ({
    sort: {
      key: 'data.emitted_at',
      direction: 'asc'
    },
    currentlyViewingData: {},
    currentlyViewingEvent: {},
    currentlyViewingType: '',
    showDataViewer: false
  }),

  methods: {
    getSeverityClass(severity) {
      switch (severity) {
      case 'L':
        return 'text-success'
      case 'M':
        return 'text-warning'
      case 'H':
        return 'text-danger'
      default:
        return 'text-primary'
      }
    },
    justification(event) {
      return this._.get(event, 'data.justification', null)
    },
    hasJustification(events) {
      return false
      // return events.some((event) => {
      //   return 'justification' in event.data
      // })
    },
    getDataViewerPopupTitle() {
      const type = this.currentlyViewingType
      const typei18nKey = `questionnaire_events.event.${type}`
      return `Evento: ${this.$t(typei18nKey)}`
    },
    formattedDate(dateString) {
      if (dateString !== null) {
        return this.$utils.format.dateWithFormat(dateString, 'DD/MM/YYYY - kk:mm:ss')
      } else {
        return '!'
      }
    },
    openDataViewer(event) {
      const type = event.type
      const data = event.data
      this.showDataViewer = true
      this.currentlyViewingEvent = event
      this.currentlyViewingData = {
        emitted_at: data.emitted_at,
        text: data.text,
        ...(
          ( data.justification && {justification: data.justification} ) ||
          null
        ),
        ...data
      }
      this.currentlyViewingType = type
    },
    handleSort(key, direction) {
      if (key !== null && direction !== null) {
        this.sort = {
          key,
          direction
        }
      } else {
        this.sort = {
          key: 'data.emitted_at',
          direction: 'asc'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
._event-detail-text {
  max-height: 4rem;
}
</style>
