var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", { staticClass: "float-right pb-2" }, [
        _vm._v(" " + _vm._s(_vm.data.length) + " Eventos"),
      ]),
      _c(
        "vs-table",
        {
          attrs: {
            data: _vm.sortedData,
            sst: true,
            "current-sort-type": "asc",
          },
          on: { sort: _vm.handleSort },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var data = ref.data
                return _vm._l(data, function (tr, i) {
                  return _c(
                    "vs-tr",
                    { key: data[i] },
                    [
                      _c("vs-td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formattedDate(
                                _vm._.get(tr, "data.emitted_at", null)
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("vs-td", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "_event-detail-text overflow-y-scroll text-sm",
                          },
                          [
                            _c("div", {
                              directives: [
                                {
                                  name: "html-safe",
                                  rawName: "v-html-safe",
                                  value: _vm._.get(
                                    tr,
                                    "data.text",
                                    "<label class='text-danger'>VAZIO</label>"
                                  ),
                                  expression:
                                    "_.get(tr, 'data.text', '<label class=\\'text-danger\\'>VAZIO</label>')",
                                },
                              ],
                            }),
                          ]
                        ),
                      ]),
                      _vm.hasJustification(data)
                        ? _c("vs-td", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "_event-detail-text overflow-y-scroll break-all",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.justification(tr)) + " "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "vs-td",
                        { class: _vm.getSeverityClass(tr.severity) },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("questionnaire_events.event." + tr.type)
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("vs-td", [_vm._v(" " + _vm._s(tr.type) + " ")]),
                      _c("vs-td", [
                        _vm._v(
                          " " + _vm._s(_vm._.get(tr, "supervisor_name")) + " "
                        ),
                      ]),
                      _c(
                        "vs-td",
                        [
                          _c("feather-icon", {
                            staticClass: "cursor-pointer",
                            attrs: {
                              icon: "EyeIcon",
                              svgClasses:
                                "h-5 w-5 mb-1 mr-3 stroke-current text-success",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openDataViewer(tr)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                })
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c(
                "vs-th",
                { attrs: { width: "20%", "sort-key": "data.emitted_at" } },
                [_vm._v("Data/Hora")]
              ),
              _c("vs-th", { attrs: { "sort-key": "data.text" } }, [
                _vm._v("Descrição"),
              ]),
              _vm.hasJustification(_vm.data)
                ? _c("vs-th", { attrs: { "sort-key": "data.justification" } }, [
                    _vm._v("Justificativa"),
                  ])
                : _vm._e(),
              _c("vs-th", { attrs: { "sort-key": "type" } }, [
                _vm._v("Evento"),
              ]),
              _c("vs-th", { attrs: { "sort-key": "type" } }, [_vm._v("Tipo")]),
              _c("vs-th", { attrs: { "sort-key": "supervisor_name" } }, [
                _vm._v("Supervisor"),
              ]),
              _c("vs-th", [_vm._v("Ações")]),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.getDataViewerPopupTitle(),
            active: _vm.showDataViewer,
          },
          on: {
            "update:active": function ($event) {
              _vm.showDataViewer = $event
            },
          },
        },
        [
          _vm.currentlyViewingEvent && "type" in _vm.currentlyViewingEvent
            ? _c("div", { staticClass: "mb-4 flex flex-col" }, [
                _c("div", [
                  _c("label", { staticClass: "font-bold" }, [
                    _vm._v("Tipo de Evento: "),
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._.get(_vm.currentlyViewingEvent, "type"))
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("data-viewer", { attrs: { data: _vm.currentlyViewingData } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }