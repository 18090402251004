var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", { staticClass: "float-right pb-2" }, [
        _vm._v(" " + _vm._s(_vm.data.length) + " Eventos"),
      ]),
      _c("vx-timeline", {
        key: _vm.timelineFormattedEvents,
        staticClass: "w-full",
        attrs: {
          timeFormat: "literal",
          html: true,
          data: _vm.timelineFormattedEvents,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }