var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.questionnaire
        ? _c(
            "vx-card",
            { staticClass: "mb-3" },
            [
              _c("questionnaire-information", {
                attrs: { answer: _vm.answer, questionnaire: _vm.questionnaire },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("vx-card", [
        _c(
          "div",
          { staticClass: "grid w-full grid-cols-12" },
          [
            _c(
              "div",
              {
                staticClass:
                  "col-span-12 row-span-1 flex gap-4 flex-row justify-between items-center",
              },
              [
                _c("div", { staticClass: "grid" }, [
                  _c(
                    "label",
                    { staticClass: "col-span-3 p- font-medium text-grey" },
                    [_vm._v(" CRITICIDADE DOS EVENTOS ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex gap-2 flex-grow-0 select-none" },
                    [
                      _c(
                        "div",
                        {
                          class: _vm.severityFilterVisuals("L", "black"),
                          on: {
                            click: function ($event) {
                              return _vm.filterSeverity("L")
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "h-4 w-4 text-success",
                            attrs: { icon: "exclamation" },
                          }),
                          _c("span", { staticClass: "text-success" }, [
                            _vm._v("Baixa"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          class: _vm.severityFilterVisuals("M", "black"),
                          on: {
                            click: function ($event) {
                              return _vm.filterSeverity("M")
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "h-4 w-4 text-warning",
                            attrs: { icon: "exclamation-circle" },
                          }),
                          _c("span", { staticClass: "text-warning" }, [
                            _vm._v("Média"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          class: _vm.severityFilterVisuals("H", "black"),
                          on: {
                            click: function ($event) {
                              return _vm.filterSeverity("H")
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "h-4 w-4 text-danger",
                            attrs: { icon: "exclamation-triangle" },
                          }),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v("Alta"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "flex flex-row gap-2" },
                  [
                    _c("label", [_vm._v("Tabela")]),
                    _c("vs-switch", {
                      model: {
                        value: _vm.viewTimeline,
                        callback: function ($$v) {
                          _vm.viewTimeline = $$v
                        },
                        expression: "viewTimeline",
                      },
                    }),
                    _c("label", [_vm._v("Timeline")]),
                  ],
                  1
                ),
              ]
            ),
            _c("vs-divider", { staticClass: "col-span-12" }),
            _vm.viewTimeline
              ? _c(
                  "div",
                  { staticClass: "col-span-12" },
                  [
                    _c("questionnaire-event-details-timeline", {
                      attrs: { data: _vm.filteredEvents },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "col-span-12" },
                  [
                    _c("questionnaire-event-details-grid", {
                      attrs: { data: _vm.filteredEvents },
                    }),
                  ],
                  1
                ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }