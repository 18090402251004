<template>
  <div>
    <vx-card v-if="questionnaire" class="mb-3">
      <questionnaire-information :answer="answer" :questionnaire="questionnaire"/>
    </vx-card>
    <vx-card>
      <div class="grid w-full grid-cols-12">

        <!-- Filters & View Mode -->
        <div class="col-span-12 row-span-1 flex gap-4 flex-row justify-between items-center">
          <!-- Filters -->
          <div class="grid">
            <label class="col-span-3 p- font-medium text-grey"> CRITICIDADE DOS EVENTOS </label>
            <div class="flex gap-2 flex-grow-0 select-none">
              <div @click="filterSeverity('L')" :class="severityFilterVisuals('L', 'black')">
                <font-awesome-icon icon="exclamation" class="h-4 w-4 text-success"/>
                <span class="text-success">Baixa</span>
              </div>
              <div @click="filterSeverity('M')" :class="severityFilterVisuals('M', 'black')">
                <font-awesome-icon icon="exclamation-circle" class="h-4 w-4 text-warning"/>
                <span class="text-warning">Média</span>
              </div>
              <div @click="filterSeverity('H')" :class="severityFilterVisuals('H', 'black')">
                <font-awesome-icon icon="exclamation-triangle" class="h-4 w-4 text-danger"/>
                <span class="text-danger">Alta</span>
              </div>
            </div>
          </div>
          <!-- View Mode -->
          <div class="flex flex-row gap-2">
            <label>Tabela</label>
            <vs-switch v-model="viewTimeline" />
            <label>Timeline</label>
          </div>
        </div>

        <vs-divider class="col-span-12" />

        <!-- TIMELINE -->
        <div v-if="viewTimeline" class="col-span-12">
          <questionnaire-event-details-timeline :data="filteredEvents"/>
        </div>

        <!-- GRID -->
        <div v-else class="col-span-12">
          <questionnaire-event-details-grid :data="filteredEvents"/>
        </div>

      </div>
    </vx-card>
  </div>
</template>

<script>
import QuestionnaireAnswerService from '@/services/api/QuestionnaireAnswerService'
import QuestionnaireEventDetailsGrid from './QuestionnaireEventDetailsGrid.vue'
import QuestionnaireEventDetailsTimeline from './QuestionnaireEventDetailsTimeline.vue'
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import QuestionnaireInformation from './QuestionnaireInformation.vue'
export default {
  components: { QuestionnaireEventDetailsTimeline, QuestionnaireEventDetailsGrid, QuestionnaireInformation },

  props: {
    // Questionnaire Answer Id
    id: {
      default: null,
      type: Number
    },
  },

  data: () => ({
    viewTimeline: false,
    questionnaireAnswerService: null,
    questionnaireService: null,
    events: [],
    filters: {
      severity: null,
    },
    questionnaire: null,
    answer: null
  }),

  computed: {
    filteredEvents() {
      const severity = this.filters.severity
      const events = this.events

      if (severity !== null) {
        return events.filter((event) => event.severity === severity)
      } else {
        return events
      }
    }
  },

  created() {
    this.service = QuestionnaireAnswerService.build(this.$vs)
    this.questionnaireService = ContentQuestionnaireService.build(this.$vs)
  },

  mounted() {
    if (this.id !== null) {
      this.$vs.loading()
      this.service.read(this.id).then(
        (response) => {
          this.answer = response
          const questionnaire = this._.get(response, 'content_questionnaire', null)
          const questionnaireId = this._.get(response, 'questionnaire_id', null)
          if (questionnaire) this.questionnaire = questionnaire
          if (!questionnaire && questionnaireId) {
            this.questionnaireService.getContentSimple(questionnaireId).then(
              (response) => {
                this.questionnaire = response
              },
              (error) => {
                this.notifyError(this.$vs, 'Erro! Não foi possível carregar o questionário.')
              }
            )
          }
        },
        (error) => {
          this.notifyError(this.$vs, 'Erro! Não foi possível carregar o questionário através dos dados da resposta.')
        }
      )
      this.service.getEvents(this.id).then(
        (response) => {
          if (response && this._.isArray(response) && response.length > 0) this.events = response
          else this.notifyWarning(this.$vs, 'Não há nenhum evento ou essa resposta/tentativa da avaliação não existe.')
          this.$vs.loading.close()
        },
        (error) => {
          this.$vs.loading.close()
          this.notifyError(this.$vs, 'Erro! Não foi possível carregar os eventos.')
        }
      )
    }
  },

  methods: {
    filterSeverity(severity) {
      if (this.filters.severity === severity) this.filters.severity = null
      else this.filters.severity = severity
    },
    severityFilterVisuals(filter, color = 'primary', extra = 'flex px-2 items-center cursor-pointer gap-1') {
      const borderDefinition = `border-${color} border-little-round border-shadowed`
      const colorized = this.filters.severity === filter ? `stroke-current bg-${color} ${borderDefinition}` : ''
      return [
        colorized,
        extra
      ]
    },
  },
}
</script>

<style>

</style>
