<template>
  <div>
    <label class="float-right pb-2"> {{data.length}} Eventos</label>
    <vx-timeline
        :key="timelineFormattedEvents"
        timeFormat="literal"
        class="w-full"
        :html="true"
        :data="timelineFormattedEvents"/>
  </div>
</template>

<script>
import VxTimeline from '@/components/timeline/VxTimeline.vue'
import ProctoringClassroomEventList from '../../proctoring/components/ProctoringClassroomEventList.vue'
import { arraySortDate } from '@/util/Util'
import { proctoring } from '@/util/Constants'
export default {
  components: { ProctoringClassroomEventList, VxTimeline },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    timelineFormattedEvents() {
      const events = this._.cloneDeep(this.data)

      let formattedEvents = events.map((event) => {
        const emission = _.get(event, 'data.emitted_at')
        const eventText = this._.get(event,  'data.text', ' ')
        const icon = this._.get(proctoring, `events.icons.${event.type}`, 'InfoIcon')

        return {
          title: this.$t(`questionnaire_events.event.${event.type}`),
          desc: eventText,
          color: (event.severity && this.$t(`questionnaire_events.colors.${event.severity}`)) || '',
          icon: icon,
          time: this.$moment(emission)
        }
      })

      // formattedEvents = formattedEvents.sort(arraySortDate('time'))

      return formattedEvents
    },
  }
}
</script>

<style>

</style>
