import { render, staticRenderFns } from "./QuestionnaireEventDetailsGrid.vue?vue&type=template&id=516b3a52&scoped=true&"
import script from "./QuestionnaireEventDetailsGrid.vue?vue&type=script&lang=js&"
export * from "./QuestionnaireEventDetailsGrid.vue?vue&type=script&lang=js&"
import style0 from "./QuestionnaireEventDetailsGrid.vue?vue&type=style&index=0&id=516b3a52&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "516b3a52",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/user/workspace/educatena/educatena-app-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('516b3a52')) {
      api.createRecord('516b3a52', component.options)
    } else {
      api.reload('516b3a52', component.options)
    }
    module.hot.accept("./QuestionnaireEventDetailsGrid.vue?vue&type=template&id=516b3a52&scoped=true&", function () {
      api.rerender('516b3a52', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/questionnaires/events/QuestionnaireEventDetailsGrid.vue"
export default component.exports